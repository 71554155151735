import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const CheckBox = ({ id, label, fontSize, onChange, checked, ...props }) => {
  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();

      // Create a synthetic event to pass to the onChange handler
      const syntheticEvent = {
        ...event,
        target: {
          ...event.target,
          checked: !checked,
        },
      };

      onChange(syntheticEvent);
    }
  };

  return (
    <LabelCheckBox fontSize={fontSize}>
      <input
        id={id}
        type="checkbox"
        checked={checked}
        onChange={onChange}
        onKeyDown={handleKeyDown}
        {...props}
      />
      <span className="checkmark" />
      <span style={{ marginLeft: 0, marginTop: 3 }}>{label}</span>
    </LabelCheckBox>
  );
};

CheckBox.propTypes = {
  checked: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  label: PropTypes.node.isRequired,
  fontSize: PropTypes.string,
};

CheckBox.defaultProps = {
  checked: false,
  fontSize: '14px',
};

export default CheckBox;

const LabelCheckBox = styled.label`
  display: flex;
  position: relative;
  gap: 8px;
  cursor: pointer;
  user-select: none;
  line-height: 1.3;
  font-size: ${({ fontSize }) => fontSize};

  input {
    position: absolute;
    opacity: 0;
    height: 0;
    width: 0;
    cursor: pointer;
  }

  .checkmark {
    flex-shrink: 0;
    position: relative;
    height: 15px;
    width: 15px;
    min-width: 15px;
    margin-top: 3px;
    border: 0.5px solid var(--way-colors-buttonColorShades-100);
    box-shadow: var(--way-design-boxShadow-s);
    border-radius: 4px;
    transition: border-color 0.2s;

    &:after {
      content: '';
      position: absolute;
      display: none;
      left: 4.2px;
      top: 2px;
      width: 4px;
      height: 8px;
      border: solid var(--way-colors-buttonColorShades-100);
      border-width: 0 2px 2px 0;
      transform: rotate(45deg);
    }
  }

  /* Show checkmark when input is checked */
  input:checked + .checkmark:after {
    display: block;
  }

  /* Add focus styles */
  input:focus + .checkmark {
    border-color: var(--way-colors-borderFocusColor);
    box-shadow: 0 0 0 3px rgba(0, 0, 255, 0.2);
  }
`;
