export default {
  buyTickets: 'Comprar entradas',
  salesEnded: 'Ventas terminadas',
  joinWaitlist: 'Unirse a la lista de espera',
  soldOut: 'Agotado',
  noDatesAvailable: 'No hay fechas disponibles',
  seeMoreDates: 'Ver más fechas',
  about: 'Acerca de',
  agenda: 'Agenda',
  menu: 'Menú',
  included: 'Incluido',
  viewMore: 'Ver más',
  viewLess: 'Ver menos',
  location: 'Ubicación',
  time: 'Hora',
  at: 'a',
  noTimesAvailableOnThisDateFotThisPartySize:
    'No hay horarios disponibles en esta fecha para este tamaño de grupo',
  availableTimes: 'Horarios disponibles',
  waitlist: 'Lista de espera',
  continue: 'Continuar',
  ticketsAreNoLongerAvailable: 'Las entradas ya no están disponibles.',
  checkOutOutOtherExperiences: '¡Descubre nuestras otras experiencias!',
  viewExperiences: 'Ver experiencias',
  from: 'de',
  rsvp: 'RSVP',
};
