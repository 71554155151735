export default {
  buyTickets: 'Koupit lístky',
  salesEnded: 'Prodej ukončen',
  joinWaitlist: 'Připojit se na čekací listinu',
  soldOut: 'Vyprodáno',
  noDatesAvailable: 'Žádné dostupné termíny',
  seeMoreDates: 'Zobrazit více termínů',
  about: 'O',
  agenda: 'Program',
  menu: 'Menu',
  included: 'Zahrnuto',
  viewMore: 'Zobrazit více',
  viewLess: 'Zobrazit méně',
  location: 'Místo',
  time: 'Čas',
  at: 'v',
  noTimesAvailableOnThisDateFotThisPartySize:
    'Na tento datum nejsou k dispozici žádné časy pro tuto velikost skupiny',
  availableTimes: 'Dostupné časy',
  waitlist: 'Čekací listina',
  continue: 'Pokračovat',
  ticketsAreNoLongerAvailable: 'Lístky již nejsou k dispozici.',
  checkOutOutOtherExperiences: 'Podívejte se na naše další zážitky!',
  viewExperiences: 'Zobrazit zážitky',
  from: 'od',
  rsvp: 'RSVP',
};
