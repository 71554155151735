import themePalette, { PaletteColorShades } from './themePalette';

export const SET_THEME_COLOR = '@theme/set-theme-color';
export const SET_THEME_FONT = '@theme/set-theme-font';
export const SET_THEME_MODE = '@theme/set-theme-mode';

export type ThemePalette = typeof themePalette;

export interface IAppTheme {
  isBaseTheme: boolean;
  mode: 'dark' | 'default';
  palette: ThemePalette;
  colors: IAppColors;
  darkColors: IAppColors;
  font: {
    primaryFont: string;
    secondaryFont: string;
  };
  design: IAppThemeDesign;
  darkDesign: IAppThemeDesign;
}

interface IAppColors {
  primaryColorShades: PaletteColorShades;
  secondaryColorShades: PaletteColorShades;
  buttonColorShades: PaletteColorShades;
  primaryColorContrastShades: PaletteColorShades;
  secondaryColorContrastShades: PaletteColorShades;
  buttonColorContrastShades: PaletteColorShades;
  primaryTextColor: string;
  secondaryTextColor: string;
  linkColor: string;
  borderColor: string;
  borderHoverColor: string;
  borderFocusColor: string;
  borderSelectedColor: string;
  svgColor: string;
  disabledInput: string;
  inputColor: string;
  calendarInputColor: string;
  contrastColorShades: PaletteColorShades;
  coherentColorShades: PaletteColorShades;
}

interface IAppThemeDesign {
  borderRadiusDefault: string;
  boxShadow: {
    xl: string;
    l: string;
    m: string;
    s: string;
  };
}
