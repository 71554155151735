export default {
  bookExperiencePanel: 'Painel de reserva de experiência',
  calendarIcon: 'Ícone de calendário',
  coverImageForResource: 'Imagem de capa para experiências {{title}}',
  filterBy: 'Filtrar por {{filter}}',
  filterByDateRange: 'Filtrar por intervalo de datas',
  gallery: 'Galeria',
  goBack: 'Voltar para a página anterior',
  hostUserIcon: 'Ícone do usuário anfitrião',
  imageOf: 'Imagem de {{title}}',
  interactiveMapAltText: 'Mapa interativo com seleção de {{title}}',
  mediaSlides: 'Slides de mídia',
  muteVideo: 'Silenciar vídeo',
  nextPhoto: 'Próxima foto',
  noVideoPlayback: 'Sem capacidade de reprodução de vídeo',
  pauseVideo: 'Pausar vídeo',
  photoOf: 'Foto de {{name}}',
  playVideo: 'Reproduzir vídeo',
  previousPhoto: 'Foto anterior',
  reserveSelectedSlot: 'Reservar horário selecionado',
  selectDate: 'Selecionar data',
  selectParticipants: 'Selecionar participantes',
  selectSessionTime: 'Selecionar horário da sessão',
  unmuteVideo: 'Ativar som do vídeo',
  viewShoppingCart: 'Ver carrinho de compras',
  zoomIn: 'Aproximar',
  zoomOut: 'Afastar',
  selectData: 'Selecionar {{data}}',
  reserveName: 'Reservar {{name}}',
  deleteReservation: 'Eliminar la reserva de {{esperienceTitle}}',
  editParticipantsFor: 'Editar participantes para {{esperienceTitle}}',
  enterPromoCode: 'Introduza o código promocional',
  applyPromoCode: 'Aplicar código promocional',
  increaseAddOnAmount: 'Aumentar {{addOn}} seleccionado',
  decreaseAddOnAmount: 'Diminuir {{addOn}} seleccionado',
  addOnModal: '{{addOn}} modal',
  moreInformationAboutAddOn: 'Mais informações sobre {{addOn}}',
};
