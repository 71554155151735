export default {
  bookExperiencePanel: 'Erlebnisbuchungspanel',
  calendarIcon: 'Kalendersymbol',
  coverImageForResource: 'Titelbild für {{title}}-Erlebnisse',
  filterBy: 'Filtern nach {{filter}}',
  filterByDateRange: 'Nach Datumsbereich filtern',
  gallery: 'Galerie',
  goBack: 'Zurück zur vorherigen Seite',
  hostUserIcon: 'Symbol für Gastgeber',
  imageOf: 'Bild von {{title}}',
  interactiveMapAltText: 'Interaktive Karte mit auswählbarem {{title}}',
  mediaSlides: 'Mediendiashow',
  muteVideo: 'Video stummschalten',
  nextPhoto: 'Nächstes Foto',
  noVideoPlayback: 'Keine Videowiedergabekapazität',
  pauseVideo: 'Video pausieren',
  photoOf: 'Foto von {{name}}',
  playVideo: 'Video abspielen',
  previousPhoto: 'Vorheriges Foto',
  reserveSelectedSlot: 'Ausgewählten Slot reservieren',
  selectDate: 'Datum auswählen',
  selectParticipants: 'Teilnehmer auswählen',
  selectSessionTime: 'Sitzungszeit auswählen',
  unmuteVideo: 'Stummschaltung des Videos aufheben',
  viewShoppingCart: 'Warenkorb ansehen',
  zoomIn: 'Vergrößern',
  zoomOut: 'Verkleinern',
  selectData: 'Wählen Sie {{data}}',
  reserveName: 'Reservieren Sie {{name}}',
  deleteReservation: 'Reservierung von {{esperienceTitle}} löschen',
  editParticipantsFor: 'Teilnehmer für {{esperienceTitle}} bearbeiten',
  enterPromoCode: 'Promo-Code eingeben',
  applyPromoCode: 'Promo-Code anwenden',
  increaseAddOnAmount: 'Erhöhen Sie die ausgewählte {{addOn}}',
  decreaseAddOnAmount: 'Verringern Sie die ausgewählte {{addOn}}',
  addOnModal: '{{addOn}} popup',
  moreInformationAboutAddOn: 'Weitere Informationen zu {{addOn}}',
};
